import React, { useMemo } from "react"
import type { AppProps } from "next/app"
import "../styles/antd.less"
import { globalStyles, theme } from "../styles/styles"
import { DefaultSeo } from "next-seo"
import SEO from "../next-seo.config"
import NextNprogress from "nextjs-progressbar"
import * as moment from "moment"
import "moment/locale/cs"
moment.locale("cs")
import { SessionProvider, useSession } from "next-auth/react"
import { Auth } from "../components/auth/Auth"
import { Session } from "next-auth"
import CookieBanner from "../components/Cookiebanner"
import GoogleAnalytics from "../components/GoogleAnalytics"
import { Analytics } from "@vercel/analytics/react"
import { ConfigProvider } from "antd"
import csCZ from "antd/lib/locale/cs_CZ"
import "moment/locale/cs"
import { NextComponentType, NextPageContext } from "next"

moment.locale("cs")

const InnerApp = ({ Component, pageProps }: InnerAppProps) => {
  const { status, data } = useSession()
  const isVzLoggedIn = useMemo(() => status === "authenticated" && data?.user?.email?.includes("@vzdelanibudoucnosti.cz"), [data?.user?.email, status])

  return (
    <>
      <ConfigProvider locale={csCZ}>
        {/* @ts-ignore */}
        {!!Component?.auth ? (
          <Auth>
            <Component {...pageProps} />
          </Auth>
        ) : (
          <Component {...pageProps} />
        )}
      </ConfigProvider>

      <Analytics
        beforeSend={(event) => {
          if (event.url.includes("/admin") || isVzLoggedIn) {
            return null
          }

          return event
        }}
      />
    </>
  )
}

interface InnerAppProps {
  Component: NextComponentType<NextPageContext, any, any>
  pageProps: any
}

const MyApp = ({
  Component,
  pageProps: { session, ...pageProps }
}: AppProps<{
  session: Session
}>) => {
  return (
    <>
      <GoogleAnalytics GA_MEASUREMENT_ID={process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID} />

      {globalStyles}
      <DefaultSeo {...SEO} />

      <CookieBanner />

      <NextNprogress color={theme.colors["primary-color"]} startPosition={0.3} stopDelayMs={200} height={3} showOnShallow={true} />

      <SessionProvider session={session} refetchInterval={5 * 60}>
        <InnerApp Component={Component} pageProps={pageProps} />
      </SessionProvider>
    </>
  )
}

export default MyApp
